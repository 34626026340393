import React from "react";
import {
    Box,
    ColumnLayout,
    Container,
    Flashbar,
    FormField,
    Header,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react/polaris";
import { FremontButton, FremontInput, FremontSelect, ComponentConstants, requiredLabel } from "utils/CommonComponents";
import FremontTable from "table/FremontTable";
import Constants from "utils/Constants";
import HelperFunctions from "common/HelperFunctions";

export const serviceTypeOptions = HelperFunctions.createSelectedOptions(Object.values(Constants.SERVICE_TYPES));
export const orderRegionOptions = HelperFunctions.createSelectedOptions(
    Object.values(Constants.ORDER_GEOGRAPHIC_REGION)
);

export const NewStageSlaModalForm = props => (
    <div>
        <Modal
            visible={props.newStageSlaModalVisible}
            header="Add a New Stage SLA"
            onDismiss={props.handleDismissNewStageSlaModal}
            size="large"
            footer={
                <Box variant="span" float="right">
                    <SpaceBetween direction="horizontal" size={ComponentConstants.SPACE_BETWEEN_BUTTON_PADDING}>
                        <FremontButton
                            variant="normal"
                            onClick={props.handleDismissNewStageSlaModal}
                            disabled={props.isSubmissionInProgress}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id="newStageSla"
                            variant="primary"
                            onClick={props.handleSubmit}
                            loading={props.isSubmissionInProgress}
                        >
                            {Constants.SUBMISSION_STRINGS.readyToSubmit}
                        </FremontButton>
                    </SpaceBetween>
                </Box>
            }
        >
            <Flashbar
                items={props.flashbar.text ?
                    HelperFunctions.generateErrorMessageForFlashbar(props.flashbar.text) : []}
            />
            <ColumnLayout columns={2} borders="vertical">
                <FormField
                    label={requiredLabel("Service Type:")}
                    errorText={props.errorTexts[Constants.ATTRIBUTES.serviceType]}
                >
                    <FremontSelect
                        id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.serviceType}`}
                        options={serviceTypeOptions}
                        selectedOption={HelperFunctions.createSelectedOption(props.newStageSlaObject.serviceType)}
                        disabled={props.isSubmissionInProgress}
                        onChange={props.handleNewStageSlaInputChange}
                    />
                </FormField>
                <FormField
                    label={requiredLabel("Order Type:")}
                    errorText={props.errorTexts[Constants.ATTRIBUTES.orderType]}
                >
                    <FremontSelect
                        id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.orderType}`}
                        options={Constants.RESOURCE_ORDER_TYPES_ARRAY}
                        selectedOption={
                            HelperFunctions.createSelectedOption(props.newStageSlaObject.orderType)
                        }
                        onChange={props.handleNewStageSlaInputChange}
                        disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                    />
                </FormField>
                {props.newStageSlaObject.serviceType !== Constants.SERVICE_TYPES.BACKBONE &&
                    <FormField
                        label={requiredLabel("Order Geographic Region:")}
                        errorText={props.errorTexts[Constants.ATTRIBUTES.geographicRegion]}
                    >
                        <FremontSelect
                            id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.geographicRegion}`}
                            options={orderRegionOptions}
                            selectedOption={
                                HelperFunctions.createSelectedOption(props.newStageSlaObject.geographicRegion)}
                            disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                            onChange={props.handleNewStageSlaInputChange}
                        />
                    </FormField>
                }
                {props.newStageSlaObject.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
                <FormField
                    label={requiredLabel("Customer Fabric:")}
                    errorText={props.errorTexts[Constants.ATTRIBUTES.customerFabric]}
                >
                    <FremontSelect
                        id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.customerFabric}`}
                        options={HelperFunctions.createSelectedOptions(
                            Object.values(Constants.CUSTOMER_FABRICS)
                        )}
                        selectedOption={HelperFunctions.createSelectedOption(props.newStageSlaObject.customerFabric)}
                        disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                        onChange={props.handleNewStageSlaInputChange}
                    />
                </FormField>
                }
                {props.newStageSlaObject.serviceType === Constants.SERVICE_TYPES.BACKBONE &&
                    <FormField
                        label={requiredLabel("Is Provider Amazon Internal (Optical Team) ? :")}
                        errorText={props.errorTexts[Constants.ATTRIBUTES.providerName]}
                    >
                        <FremontSelect
                            id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.providerName}`}
                            options={HelperFunctions.createSelectedOptions(
                                Object.values([Constants.INTERNAL_AMAZON_PROVIDER,
                                    Constants.NON_INTERNAL_AMAZON_PROVIDER])
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(props.newStageSlaObject.providerName)}
                            disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                            onChange={props.handleNewStageSlaInputChange}
                        />
                    </FormField>
                }
                {Constants.PROJECT_SPECIFIC_BACKBONE_FABRICS.includes(props.newStageSlaObject.customerFabric) &&
                    Constants.INTERNAL_AMAZON_PROVIDER === props.newStageSlaObject.providerName &&
                    <FormField
                        label={requiredLabel("Project Type:")}
                        errorText={props.errorTexts[Constants.ATTRIBUTES.projectType]}
                    >
                        <FremontSelect
                            id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.projectType}`}
                            options={HelperFunctions.createSelectedOptions(
                                Object.values(Constants.PROJECT_TYPES)
                            )}
                            selectedOption={HelperFunctions.createSelectedOption(props.newStageSlaObject.projectType)}
                            disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                            onChange={props.handleNewStageSlaInputChange}
                        />
                    </FormField>
                }
                {props.newStageSlaObject.serviceType !== Constants.SERVICE_TYPES.BACKBONE &&
                <FormField
                    label={requiredLabel("Priority:")}
                    errorText={props.errorTexts[Constants.ATTRIBUTES.priorityType]}
                >
                    <FremontSelect
                        id={`${Constants.SEPARATOR}${Constants.ATTRIBUTES.priorityType}`}
                        options={HelperFunctions.createSelectedOptions(
                            Object.keys(Constants.ORDER_PRIORITY_OPTIONS)
                        )}
                        selectedOption={HelperFunctions.createSelectedOption(props.newStageSlaObject.priorityType)}
                        disabled={props.isSubmissionInProgress || !props.newStageSlaObject.serviceType}
                        onChange={props.handleNewStageSlaInputChange}
                    />
                </FormField>
                }
            </ColumnLayout>
        </Modal>
    </div>
);

export const StageSlasTablesCommonHeader = props => (
    <Header
        variant="h2"
        actions={
            props.editMode ?
                <div>
                    <SpaceBetween direction="horizontal" size="xs">
                        <FremontButton
                            id={props.id}
                            onClick={props.handleEditModeChange}
                            loading={props.isLoading}
                        >
                            Cancel
                        </FremontButton>
                        <FremontButton
                            id={props.id}
                            variant="primary"
                            onClick={props.handleSubmitUpdate}
                            loading={props.isLoading}
                        >
                            Submit
                        </FremontButton>
                    </SpaceBetween>
                </div>
                :
                <div>
                    <SpaceBetween direction="horizontal" size="xs">
                        <FremontButton
                            id={props.id}
                            iconName="edit"
                            variant="primary"
                            onClick={props.handleEditModeChange}
                            disabled={props.isDisabled}
                            className={props.id}
                        />
                    </SpaceBetween>
                </div>
        }
    >
        {props.title}
    </Header>
);

export const validateOpticalColumnsStageSla = (customerFabric, stage, providerName) => {
    // SLA for carrierBuild order will be auto-calculated, we thus disable the Stage SLA Input for carrierBuild.
    // For the rest of the stages we check if it needs to be included based on the customer fabric and provider name
    if (Constants.BACKBONE_SPAN_OPTICAL_COLUMNS.includes(stage) ||
        Constants.BACKBONE_PATH_OPTICAL_COLUMNS.includes(stage)) {
        if (Constants.PROVIDER_SPECIFIC_BACKBONE_FABRICS.includes(customerFabric)) {
            return providerName !== Constants.INTERNAL_AMAZON_PROVIDER;
        }
        return true;
    } return Constants.STAGE_NAMES.carrierBuild === stage;
};

export const getStageCells = (editMode, stages, handleInputChange) => {
    let serviceType = "";
    let providerName = "";
    let customerFabric = "";
    const array = [{
        id: Constants.ATTRIBUTES.orderVariant,
        header: "Order Variant",
        width: 350,
        cell: (item) => {
            const [service, fabric, , provider] = item.orderVariant.split(Constants.POUND_SEPARATOR);
            // Below variables Only to be used in case Stage Sla is for BackBone Service Type.
            serviceType = service;
            customerFabric = fabric;
            providerName = provider;
            return <div>{item.orderVariant.replaceAll("#", " - ")}</div>;
        }
    }];
    stages.forEach(stage =>
        array.push(Object.assign({
            id: stage,
            header: stage,
            width: 165,
            cell: item => (!editMode ?
                <div> {item[Constants.ATTRIBUTES.stageMap][stage]} </div>
                :
                <FremontInput
                    id={`${item.stageSlaId}${Constants.SEPARATOR}${stage}`}
                    value={item[Constants.ATTRIBUTES.stageMap][stage]}
                    onInput={handleInputChange}
                    type={Constants.NUMBER}
                    disabled={Constants.SERVICE_TYPES.BACKBONE === serviceType ?
                        validateOpticalColumnsStageSla(customerFabric, stage, providerName) : false}
                />
            )
        })));
    return array;
};

export const filterSlas = (stageSlas, serviceTypes, orderTypes, customerFabricTypes = []) =>
    stageSlas.filter((stageSla) => {
        const orderVariantParts = stageSla.orderVariant.split(Constants.POUND_SEPARATOR);
        const serviceType = orderVariantParts[0];
        const fabricType = orderVariantParts[1];
        const orderType = orderVariantParts[2];
        const isServiceTypeIncluded = serviceTypes.includes(serviceType);
        const isOrderTypeIncluded = orderTypes.includes(orderType);
        const isCustomerFabricTypeIncluded = serviceType === Constants.SERVICE_TYPES.BACKBONE
            ? customerFabricTypes.includes(fabricType)
            : true;
        return isServiceTypeIncluded && isOrderTypeIncluded && isCustomerFabricTypeIncluded;
    });


export const GenericSlaVariant = props => (
    <div>
        <Container
            header={
                <StageSlasTablesCommonHeader
                    id={props.slaVariantType}
                    handleEditModeChange={props.handleEditModeChange}
                    handleSubmitUpdate={props.handleSubmit}
                    editMode={props.editMode}
                    title={props.slaVariantType}
                    isDisabled={props.isDisabled}
                    isLoading={props.isLoading}
                />
            }
        >
            <div>
                <FremontTable
                    entity={props.slaVariantType}
                    columnDefinitions={getStageCells(props.editMode, props.orderVariant, props.handleInputChange)}
                    loading={props.isLoading}
                    tableItems={props.editMode ? props.stageSlaObjectsEditMode : props.stageSlaObjects}
                    emptyTableMessage={Constants.EMPTY_TABLE_MESSAGES.noStageSlas}
                />
            </div>
        </Container>
    </div>
);

const slaVariantTypes = [
    Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_INSTALL_STAGES,
    Constants.STAGE_SLA_TYPES.BACKBONE_PATH_INSTALL_STAGES,
    Constants.STAGE_SLA_TYPES.BACKBONE_SPAN_INSTALL_STAGES,
    Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_DECOM_STAGES
];

const orderTypeStageMap = {
    [Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_INSTALL_STAGES]:
    Constants.ORDER_TYPE_STAGE_MAP.SETTLEMENT_FREE_PEERING_INSTALL_CHANGE_STAGES,
    [Constants.STAGE_SLA_TYPES.BACKBONE_PATH_INSTALL_STAGES]:
    Constants.ORDER_TYPE_STAGE_MAP.BACKBONE_PATH_INSTALL_STAGES,
    [Constants.STAGE_SLA_TYPES.BACKBONE_SPAN_INSTALL_STAGES]:
    Constants.ORDER_TYPE_STAGE_MAP.BACKBONE_SPAN_INSTALL_STAGES,
    [Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_DECOM_STAGES]:
    Constants.ORDER_TYPE_STAGE_MAP.SETTLEMENT_FREE_PEERING_DECOM_STAGES
};

const slaVariantOrderTypes = {
    [Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_INSTALL_STAGES]: [Constants.ORDER_TYPES.INSTALL,
        Constants.ORDER_TYPES.CHANGE],
    [Constants.STAGE_SLA_TYPES.BACKBONE_PATH_INSTALL_STAGES]: [Constants.ORDER_TYPES.INSTALL],
    [Constants.STAGE_SLA_TYPES.BACKBONE_SPAN_INSTALL_STAGES]: [Constants.ORDER_TYPES.INSTALL],
    [Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_DECOM_STAGES]: [Constants.ORDER_TYPES.DECOMMISSION]
};

const slaVariantServiceTypes = {
    [Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_INSTALL_STAGES]: Constants.INTERCONNECT_SERVICE_TYPES,
    [Constants.STAGE_SLA_TYPES.BACKBONE_PATH_INSTALL_STAGES]: [Constants.SERVICE_TYPES.BACKBONE],
    [Constants.STAGE_SLA_TYPES.BACKBONE_SPAN_INSTALL_STAGES]: [Constants.SERVICE_TYPES.BACKBONE],
    [Constants.STAGE_SLA_TYPES.SETTLEMENT_FREE_PEERING_DECOM_STAGES]: Constants.INTERCONNECT_SERVICE_TYPES
};

const slaVariantCustomerFabricTypes = {
    [Constants.STAGE_SLA_TYPES.BACKBONE_PATH_INSTALL_STAGES]: Constants.PATH_CUSTOMER_FABRICS,
    [Constants.STAGE_SLA_TYPES.BACKBONE_SPAN_INSTALL_STAGES]: Constants.CONSUMABLE_CUSTOMER_FABRICS
};

export const StageSlaForm = props => (
    <SpaceBetween direction="vertical" size="xs">
        <Header
            variant="h3"
            actions={
                <FremontButton
                    id={props.id}
                    variant="primary"
                    onClick={props.handleAddNewStageSlaModal}
                >
                    Create New Sla for Order Variant
                </FremontButton>
            }
        />

        {props.isUserManager &&
            slaVariantTypes.map(slaVariantType => (
                <GenericSlaVariant
                    editMode={props.editModeTable === slaVariantType}
                    handleEditModeChange={props.handleEditModeChange}
                    handleSubmitUpdate={props.handleSubmitUpdate}
                    isDisabled={props.isSubmissionInProgress ||
                        (!!props.editModeTable && props.editModeTable !== slaVariantType)}
                    isLoading={props.isSubmissionInProgress}
                    orderVariant={orderTypeStageMap[slaVariantType]}
                    slaVariantType={slaVariantType}
                    stageSlaObjects={filterSlas(
                        props.stageSlaObjects,
                        slaVariantServiceTypes[slaVariantType],
                        slaVariantOrderTypes[slaVariantType],
                        slaVariantCustomerFabricTypes[slaVariantType] || []
                    )}
                    stageSlaObjectsEditMode={filterSlas(
                        props.stageSlaObjectsEditMode,
                        slaVariantServiceTypes[slaVariantType],
                        slaVariantOrderTypes[slaVariantType],
                        slaVariantCustomerFabricTypes[slaVariantType] || []
                    )}
                    handleInputChange={props.handleInputChange}
                    handleSubmit={props.handleSubmit}
                />
            ))}

    </SpaceBetween>
);