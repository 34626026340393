export default class DevEnvironment {
    static YOUR_USER_POOL_ID = "us-west-2_02ZxHz8to";

    static YOUR_CLIENT_ID = "7f3i5hse4hi3s0en3kb45m12un";

    static YOUR_ALIAS = "rosahk";

    static APP_WEB_DOMAIN = `fremont-${DevEnvironment.YOUR_ALIAS}-test-userpool.auth.us-west-2.amazoncognito.com`;

    static UI_API_GATEWAY_ENDPOINT = `https://${DevEnvironment.YOUR_ALIAS}.test.ui.fremont.networking.aws.a2z.com`;

    static OPERATIONS_API_GATEWAY_ENDPOINT = `https://${DevEnvironment.YOUR_ALIAS}.test.operations.fremont.networking.aws.a2z.com`;
}
